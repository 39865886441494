import moment from 'moment';

export default class Order {
    init() {
        const today = moment();
        $('#event_date').daterangepicker({
            singleDatePicker: true,
            start: today,
            locale: modules.common.daterangepicker_locale
        });
    };
};
