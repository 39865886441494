import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom/client";

import Common from './Common';
import OrderPreview from "./OrderPreview";
import OrderSearch from "./OrderSearch";
import OrderDetails from "./OrderDetails";
import Calendar from "./Calendar";
import Order from "./Order";
import CalendarReact from "./calendar/CalendarReact";
import SearchTableTemplate from './SearchTable/SearchTableTemplate';


window.modules = {};

$(document).ready(function () {
    modules.common = new Common();
    modules.common.init();
    window.common = modules.common;
    if ($('#order-preview').length) {
        modules.order_preview = new OrderPreview();
        modules.order_preview.init();
    }
    if ($('#order-search-form').length) {
        modules.order_search = new OrderSearch();
        modules.order_search.init();
    }
    if ($('#order-status-form').length) {
        modules.order_details = new OrderDetails();
        modules.order_details.init();
    }
    if ($('#calendar-form').length) {
        modules.calendar = new Calendar();
        modules.calendar.init();
    }
    if ($('#order-form').length) {
        modules.order = new Order();
        modules.order.init();
    }

    if ($('#calendar-container').length) {
        ReactDOM.createRoot(document.getElementById('calendar-container')).render(
            <CalendarReact ref={(el) => {modules.calendarReact = el}}/>,
        )
    }

    if (document.getElementById('order-search-results')) {
        ReactDOM.createRoot(document.getElementById("order-search-results")).render(
            <SearchTableTemplate ref={(searchTableTemplate) => {window.searchTableTemplate = searchTableTemplate}} />,
        );
    }

});
