import React from "react";
import CalendarForm from "./CalendarForm";
import IdSelection from "./IdSelection";
import SummaryLegend from "./SummaryLegend";
import CsvGenerator from "./CsvGenerator";
import moment from 'moment';

const { Component } = React;

export default class CalendarReact extends Component {

    state = {
        isFilterMode: true,
        orders: null,
        csvOrders: new Set(),
        year: null,
        week: null,
        isLoading: false
    }

    fetchFilterResults(data) {
        this.setState({isLoading: true, csvOrders: new Set()})
        $.ajax({
            url: '/api/calendar/filter-results',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
                source: data.source,
                week: data.week,
                year: data.year,
                purchase_status: data.purchase_status,
                shipping_status: data.shipping_status,
                packaging_status: data.packaging_status
            }),
            success: (result) => this.setState({orders: result, year: data.year, week: data.week, isLoading: false})
        })
    }

    orderCount() {
        return this.state.orders.filter(order => ['pending', 'on-hold', 'completed'].includes(order.purchase_status)).length
    }

    fetchIdResult({id, source}) {
        this.setState({isLoading: true, csvOrders: new Set()})
         $.ajax({
            url: `/api/calendar/${id}`,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({source}),
            success: (result) => {
                if (result.status) {
                    this.setState({orders: null, year: null, week: null, isLoading: false})
                } else {
                    const eventDate = moment(result.data.event_date)
                    this.setState({orders: [result.data], year: eventDate.year(), week: eventDate.week() - 1, isLoading: false})
                }
            }
        })
    }

    onExportCsv() {
        if (this.state.isFilterMode) {
            setTimeout(() => this.calendarForm.emitState(), 2000)
        } else {
            setTimeout(() => this.idSelection.emitState(), 2000)
        }
    }

    getWeekStart() {
        return moment()
            .year(this.state.year)
            .isoWeek(this.state.week)
            .isoWeekday(1)
    }

    weekDates() {
        const result = []
        for (let i = 1; i <= 7; i++) {
            result.push(this.getWeekStart().isoWeekday(i))
        }
        return result
    }

    getOrdersForDate(date) {
        return this.state.orders.filter(order => moment(order.event_date).isSame(date, 'day'))
    }

    getOrdersToPack(date) {
        return this.getOrdersForDate(date).filter(order => order.purchase_status === 'completed' && order.packaging_status !== 'ready')
    }

    addAllToCsv(date) {
        const ordersToAdd = this.getOrdersForDate(date).filter(order => order.purchase_status === 'completed' && order.packaging_status === 'templated')

        this.setState({
            csvOrders: new Set([...this.state.csvOrders, ...ordersToAdd.map(o => o.external_id_localized)]),
            orders: this.state.orders.map(o => ordersToAdd.find(orderToAdd => orderToAdd.id === o.id) ? {...o, packaging_status: 'pre-labeled'} : o )
        })
    }

    switchFilterMode(mode) {
        this.setState({isFilterMode: !this.state.isFilterMode, csvOrders: new Set(), orders: null})
    }

    onLegendSelect(field, value) {
        this.calendarForm.setValue(value, field)
    }

    setNewPackagingStatus(order, status) {
        this.setState({
            orders: this.state.orders.map(o => order.id === o.id ? {...o, packaging_status: status} : o)
        })
    }

    addToCsv(order) {
        this.setState({csvOrders: new Set([...this.state.csvOrders, order.external_id_localized])})
    }

    removeFromCsv(order) {
        this.setState({csvOrders: new Set([...this.state.csvOrders].filter(id => id !== order.external_id_localized))})
    }

    onPackagingStatusClick(order) {
        if (order.packaging_status === 'not-ready' || !order.packaging_status) {
            $.post(`/api/order/${order.id}/packaging-status`, { packaging_status: 'templated' }, (data) => {
                if (!data.status) {
                    this.setNewPackagingStatus(order, 'templated')
                }
            });
        } else if (order.packaging_status === 'templated' && order.purchase_status === 'completed' && order.source != 'dashboard'  && order.external_id) {
            this.setNewPackagingStatus(order, 'pre-labeled')
            this.addToCsv(order)
        } else if (order.packaging_status === 'pre-labeled' && order.external_id) {
            this.setNewPackagingStatus(order, 'templated')
            this.removeFromCsv(order)
        } else if (order.packaging_status === 'labeled') {
            $.post(`/api/order/${order.id}/packaging-status`, { packaging_status: 'ready' }, (data) => {
                if (!data.status) {
                    this.setNewPackagingStatus(order, 'ready')
                }
            });
        }
    }

    render() {
        return <div>
            <div className='row'>
                <div className='col'>
                    <label>Filtermodus</label><input className='ml-2 mr-4' type='radio' name='filterMode' checked={this.state.isFilterMode} onChange={(e) => this.switchFilterMode()} />
                    <label>ID Auswahl</label><input className='ml-2' type='radio' name='idMode' checked={!this.state.isFilterMode} onChange={(e) => this.switchFilterMode()}/>
                </div>
            </div>
            {this.state.isFilterMode ?
                <CalendarForm ref={(ref) => this.calendarForm = ref} onChange={(data) => this.fetchFilterResults(data)}/> :
                <IdSelection ref={(ref) => this.idSelection = ref} onChange={(id) => this.fetchIdResult(id)}/>
            }
            <div className="row row-form row-resultnav">
                { this.state.isFilterMode && <div className="col-md-1">
                    <a href="#" onClick={(e) => this.calendarForm.decrementWeek()}><i className="fa fa-arrow-circle-left"></i></a>
                </div> }
                <div className="col-md">
                    {this.state.orders &&
                        <h2>
                            {this.orderCount()} Bestellungen{' '}
                            {this.state.isLoading &&
                                <i className="fa fa-spinner fa-spin"></i>
                            }
                        </h2>
                    }
                </div>
                { this.state.isFilterMode &&<div className="col-md-1">
                    <a href="#" onClick={(e) => this.calendarForm.incrementWeek()}><i className="fa fa-arrow-circle-right"></i></a>
                </div> }
            </div>
            {this.state.orders && <div>
                <div className="row row-form">
                    <div className="col-md-12 cal-order">
                        <SummaryLegend onLegendSelect={(field, value) => this.onLegendSelect(field, value)}
                                       orderCount={this.orderCount()} orders={this.state.orders}
                                       showStatistics={this.state.isFilterMode}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col cal-order'>
                        <CsvGenerator orders={Array.from(this.state.csvOrders)} onReset={() => this.onExportCsv()}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className="cal-box">
                            {this.state.year && this.state.week && this.state.orders && this.weekDates().map((date) => {
                                return <div className="cal-col" key={date.isoWeekday()}>
                                    <h4>
                                        {date.format('dddd')}<br/>
                                        <small>{date.format('DD.MM.YYYY')}</small><br/>
                                        <small><span>{this.getOrdersToPack(date).length}</span> ungepackt</small><br/>
                                        <small className="csv-head-select" onClick={() => this.addAllToCsv(date)}><i
                                            className="fa fa-archive clickable"></i> Alle zur CSV</small>
                                    </h4>
                                    {this.getOrdersForDate(date).map(order => {
                                        return <div key={order.id}
                                                    className={['cal-order', order.purchase_status, `source-${order.source}`, order.print_custom ? 'print-custom' : ''].join(' ')}>
                                            <h5>
                                                {!!order.attention && <i className="mr-1 fa fa-exclamation-circle"
                                                                         aria-hidden="true"></i>}
                                                <i className={['fa', 'fa-shopping-cart', order.purchase_status].join(' ')}></i>
                                                <i className={['ml-1', 'fa', 'fa-archive', order.packaging_status || 'not-ready', order.purchase_status].join(' ')}
                                                   aria-hidden="true"
                                                   onClick={() => this.onPackagingStatusClick(order)}></i>
                                                <i className={['ml-1', 'fa', 'fa-truck', order.shipping_status].join(' ')}></i>
                                                <a className='ml-1' href={`/order/${order.id}`}>
                                                    {order.external_id_localized}
                                                </a>
                                            </h5>
                                            <p className="meta">{order.shipping_first_name} {order.shipping_last_name}</p>
                                        </div>
                                    })
                                    }
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    }
}
